.vidget_resp_button {
  width: max-content;
  position: fixed;
  bottom: 3%;
  left: 1%;
  z-index: 19;
}

@media screen and (max-width: 576px) {
  .vidget_resp_button {
    display: none;
  }
}

.vidget__wrapper {
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  top: -100%;
  left: 0;
  transition: opacity 0.5s;

  &.active {
    z-index: 20;
    transition: opacity 0.5s;
    opacity: 1;
    top: 0;
  }
}

.vidget__body {
  position: relative;
  background: url('../img/respublic_vidget.png') center (center / cover) no-repeat;
  margin: 150px auto;
  max-width: 500px;
  padding: 65px 0 135px 30px;
  border-radius: 24px;
}

.vidget__close {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 10px;
  top: 10px;
  cursor: pointer;

  span {
    pointer-events: none;
    display: block;
    width: 100%;
    height: 100%;
    position: relative;

    &::before,
    &::after {
      content: "";
      width: 100%;
      height: 2px;
      background: black;
      position: absolute;
      top: 50%;
    }

    &::before {
      transform: rotate(-45deg);
    }

    &::after {
      transform: rotate(45deg);
    }
  }
}

.vidget__title {
  font-weight: 700;
  font-size: 34px;
  line-height: 45px;
  color: #CD0101;

  span {
    color: #000000;
    font-size: 20px;
  }
}

.vidget__subttl {
  margin-bottom: 7px;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  color: #000000;
}

.vidget__body form {
  input {
    height: unset;
    margin-top: 7px;
    background: #F8F8F8;
    border: 1px solid #E5E5E5;
    border-radius: 47px;
    font-weight: 300;
    font-size: 15px;
    line-height: 11px;
    color: rgb(0, 0, 0);
    padding: 7px 9px;

    &::placeholder {
      font-weight: 300;
      font-size: 15px;
      line-height: 11px;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  button {
    height: unset;
    cursor: pointer;
    margin-top: 27px;
    background: #CD0101;
    border-radius: 37px;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    color: #FBFBFB;
    padding: 10px 10px;
    border: none;

    &:hover {
      transition: all 0.5s;
      background: #970202;
    }
  }
}

@media (max-width: 430px) {
  .vidget__body {
    width: 90%;
    padding: 14px 0 124px 20px;
    background: url('../img/respublic_vidget_mob.png') center (center / cover) no-repeat;
  }

  .vidget__close {
    top: -30px;
  }

  .vidget__title span {
    font-size: 16px;
  }
}