.main-selections-slider__more__btn {
  display: flex;
  justify-content: end;
}
@media screen and (max-width: 700px) {
  .main-selections-slider__more__btn {
    justify-content: center;
  }
}
.main-collection {
  margin-bottom: 70px;
}

.collection-page {
  width: 100%;
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(4, 1fr);
}

.main-selections-slider__item {
  text-decoration: none;
  overflow: hidden;
  border-radius: 8px;
}

.main-selections-slider__heading {
  height: 64px;
  background-position: center left;
  background-repeat: no-repeat;
  padding-left: 20px;
  padding-top: 10px;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: rgba(255, 190, 20, 0.99);

}

.main-selections-slider__image {
  margin: auto;
  height: 209px;
  width: 344px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1500px) {
  .collection-page {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 460px) {
  .collection-page {
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
  }

  .main-selections-slider__image {
    margin: auto;
    width: auto;
    max-width: 382px;
    background-size: contain;
  }
}